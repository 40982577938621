import { useLazyQuery } from '@apollo/client';
import { useCallback, useState } from 'react';

import { QUERY_USER_STATUS } from '~/graphql/user';
import { ActionFunctionResult } from '~/types';
import {
  UserStatusQuery,
  UserStatusQueryVariables,
} from '~/types/graphql/graphql';

export const useUserStatus = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [getUserStatus] = useLazyQuery<
    UserStatusQuery,
    UserStatusQueryVariables
  >(QUERY_USER_STATUS, { fetchPolicy: 'no-cache' });

  const handleGetUserStatus = useCallback(
    async (
      variables: UserStatusQueryVariables,
    ): Promise<ActionFunctionResult<UserStatusQuery['userStatus'], any>> => {
      try {
        setIsLoading(true);
        const { data } = await getUserStatus({ variables });
        const res = data?.userStatus;
        if (!res) throw new Error('empty response');
        return { success: true, data: res };
      } catch (err) {
        return { success: false, error: err };
      } finally {
        setIsLoading(false);
      }
    },
    [getUserStatus],
  );

  return { handleGetUserStatus, isLoading };
};

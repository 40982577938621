import { gql } from '~/types/graphql/gql';

export const QUERY_USER = gql(`
  query user {
    user: epUser {
      companyId
      username
      displayName
      phoneNumber
      registrationStatus
      roleStr
      roleId
      createdAt
      companyName
    }
  }
`);

export const QUERY_USER_CONFIG = gql(`
  query userConfig {
    userConfig: epUserConfig {
      vacancyReportSummaryPeriod
    }
  }
`);

export const MUTATION_UPDATE_USER_CONFIG = gql(`
  mutation updateUserConfig($data: EpUserConfigUpdateInput!) {
    updateUserConfig: epUpdateUserConfig(data: $data) {
      data
      isSuccess
      warning
      error
    }
  }
`);

export const MUTATION_UPDATE_USER = gql(`
  mutation updateUser($data: EpUserUpdateInput!) {
    updateUser: epUpdateUser(data: $data) {
      data
      isSuccess
      warning
      error
    }
  }
`);

// Unused
export const QUERY_USER_SUBSCRIPTION_STATUS = gql(`
  query userSubscriptionStatus($companyId: ID!) {
    userSubscriptionStatus: epUserSubscriptionStatus(companyId: $companyId) {
      packageId
      packageStr
      currentPackageStr
      expiryDate
      refreshDate
      isValid
      isDisplayInfo
      isPremium
      info
      offerPackageUpgrade
      status {
        jobPromoteMax
      }
      errors
      freeTrialDuration
      freeTrialStatus
      isFreeTrial
      packageEnum
      companyId
      jobPromoteStatus {
        jobPromoteCount
        jobPromoteExceeded
      }
    }
  }
`);

export const QUERY_USER_SUBSCRIPTION_TYPES = gql(`
  query userSubscriptionTypes($companyId: ID) {
    userSubscriptionTypes: epUserSubscriptionTypes(companyId: $companyId) {
      list {
        id
        name
        description
        isRecommended
        isSelected
        btnCopy
        btnConfirmationCopy
        confirmationAsset
        confirmationTitleCopy
        confirmationBodyCopy
        subscriptionTypeItems
      }
    }
  }
`);

export const QUERY_OFFERING_PACKAGES = gql(`
  query offeringPackages {
    offeringPackages: epOfferingPackages {
      list {
        btnCopy
        featureItems
        featureTitle
        id
        isPopular
        packageDescription
        packageName
      }
    }
  }
`);

export const MUTATION_REQUEST_USER_SUBSCRIPTION_TYPE = gql(`
  mutation requestUserSubscriptionType(
    $companyId: ID!
    $subscriptionTypeId: ID!
  ) {
    requestUserSubscriptionType: epRequestUserSubscriptionType(
      companyId: $companyId
      subscriptionTypeId: $subscriptionTypeId
    ) {
      data
      isSuccess
      warning
      error
    }
  }
`);

export const QUERY_SUBSCRIPTION_PLAN_STATUS_DETAIL = gql(`
  query subscriptionPlanStatusDetail($companyId: ID!) {
    subscriptionPlanStatusDetail: epSubscriptionPlanStatusDetail(
      companyId: $companyId
    ) {
      title
      description
      isLimit
    }
  }
`);

export const QUERY_PACKAGE_FEATURES = gql(`
  query packageFeatures {
    packageFeatures: epPackageFeatures {
      list {
        description
        expiry
        packageName
      }
    }
  }
`);

export const QUERY_USER_STATUS = gql(`
  query userStatus($email: String!) {
    userStatus: epUserStatus(email: $email) {
      status
    }
  }
`);
